import React from 'react';

export const ProgressBar = ({ languages }) => {
	return (
		<div className='w-full mt-6 items-center mb-1'>
			<div className='relative pt-1'>
				<div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200'>
					{languages.map(({ name, level, color }) => (
						<div
							key={`${name}-${level}`}
							style={{ width: `${level}%` }}
							className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${color}`}
						></div>
					))}
				</div>
				<div className='flex mb-2 items-center justify-between'>
					{languages.map(({ name, level, color }) => (
						<div key={`${name}`} style={{ width: `${level}%` }}>
							<span className={`text-xs font-semibold inline-block text-${color}`}>
								{name} - {level}%
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
