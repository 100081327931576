import { NextSeo, ArticleJsonLd } from 'next-seo';
import config from '@/config';

export const SEO = {
	title: config.title,
	description: config.description,
	openGraph: {
		type: 'website',
		locale: config.language,
		url: config.siteUrl,
		title: config.title,
		description: config.description,
		images: [
			{
				url: `${config.siteUrl}${config.socialBanner}`,
				alt: config.title,
				width: 1200,
				height: 600,
			},
		],
	},
	twitter: {
		handle: config.twitter,
		site: config.twitter,
		cardType: 'summary_large_image',
	},
	additionalMetaTags: [
		{
			name: 'author',
			content: config.author,
		},
	],
};

export const PageSeo = ({ title, description, url }) => {
	return (
		<NextSeo
			title={`${title} – ${config.title}`}
			description={description}
			canonical={url}
			openGraph={{
				url,
				title,
				description,
			}}
		/>
	);
};

export const BlogSeo = ({
	title,
	summary,
	date,
	lastmod,
	url,
	tags,
	images = [],
}) => {
	const publishedAt = date;
	const modifiedAt = lastmod || date;
	let imagesArr =
		images.length === 0
			? [config.socialBanner]
			: typeof images === 'string'
			? [images]
			: images;

	const featuredImages = imagesArr.map((img) => {
		return {
			url: `${config.siteUrl}${img}`,
			alt: title,
		};
	});

	return (
		<>
			<NextSeo
				title={`${title} – ${config.title}`}
				description={summary}
				canonical={url}
				openGraph={{
					type: 'article',
					article: {
						publishedTime: publishedAt,
						modifiedTime: modifiedAt,
						authors: [`${config.siteUrl}/about`],
						tags,
					},
					url,
					title,
					description: summary,
					images: featuredImages,
				}}
				additionalMetaTags={[
					{
						name: 'twitter:image',
						content: featuredImages[0].url,
					},
				]}
			/>
			{/* <ArticleJsonLd
				authorName={siteMetadata.author}
				dateModified={publishedAt}
				datePublished={modifiedAt}
				description={summary}
				images={featuredImages}
				publisherName={siteMetadata.author}
				title={title}
				url={url}
			/> */}
		</>
	);
};
