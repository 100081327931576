import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const LocaleSwitch = () => {
	const router = useRouter();

	return (
		<Link href={router.asPath} locale={router.locale === 'en' ? 'de' : 'en'}>
			<button
				aria-label='Toggle Dark Mode'
				type='button'
				className='w-8 h-8 p-1 ml-1 mr-1 rounded sm:ml-4'
			>
				{router.locale === 'en' ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						x='0'
						y='0'
						enableBackground='new 0 0 512 512'
						version='1.1'
						viewBox='0 0 512 512'
						xmlSpace='preserve'
					>
						<circle cx='256' cy='256' r='256' fill='#F0F0F0'></circle>
						<g fill='#0052B4'>
							<path d='M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z'></path>
						</g>
						<g fill='#D80027'>
							<path d='M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z'></path>
							<path d='M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z'></path>
						</g>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						x='0'
						y='0'
						enableBackground='new 0 0 512 512'
						version='1.1'
						viewBox='0 0 512 512'
						xmlSpace='preserve'
					>
						<path
							fill='#FFDA44'
							d='M15.923 345.043C52.094 442.527 145.929 512 256 512s203.906-69.473 240.077-166.957L256 322.783l-240.077 22.26z'
						></path>
						<path d='M256 0C145.929 0 52.094 69.472 15.923 166.957L256 189.217l240.077-22.261C459.906 69.472 366.071 0 256 0z'></path>
						<path
							fill='#D80027'
							d='M15.923 166.957C5.633 194.69 0 224.686 0 256s5.633 61.31 15.923 89.043h480.155C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043H15.923z'
						></path>
					</svg>
				)}
			</button>
		</Link>
	);
};
