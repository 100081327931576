export const Button = ({ disabled = false, action, label }) => (
	<button
		disabled={disabled}
		type='button'
		onClick={action}
		className='inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide bg-green-500 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-green-600 focus:shadow-outline focus:outline-none'
	>
		{label}
	</button>
);
