import React from 'react';

export const TagsPanel = ({ tags }) => {
	return (
		<div className='flex mt-6 items-center mb-1'>
			<ul className='list-none'>
				<li className='mb-6 flex flex-wrap'>
					{tags.map((tag) => (
						<span
							key={tag}
							className='mr-2 my-1 rounded-full border px-4 text-sm py-2 font-medium bg-green-600 dark:border-transparent text-white'
						>
							{tag}
						</span>
					))}
				</li>
			</ul>
		</div>
	);
};
