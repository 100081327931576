const DEFAULT_WALLS = [
	'{"column":19,"row":-12,"s":-7}',
	'{"column":19,"row":-11,"s":-8}',
	'{"column":-7,"row":-12,"s":19}',
	'{"column":-6,"row":-12,"s":18}',
	'{"column":18,"row":-12,"s":-6}',
	'{"column":-5,"row":-12,"s":17}',
	'{"column":-4,"row":-12,"s":16}',
	'{"column":-3,"row":-12,"s":15}',
	'{"column":-2,"row":-12,"s":14}',
	'{"column":-1,"row":-12,"s":13}',
	'{"column":0,"row":-12,"s":12}',
	'{"column":1,"row":-12,"s":11}',
	'{"column":2,"row":-12,"s":10}',
	'{"column":3,"row":-12,"s":9}',
	'{"column":7,"row":-12,"s":5}',
	'{"column":17,"row":-12,"s":-5}',
	'{"column":16,"row":-12,"s":-4}',
	'{"column":15,"row":-12,"s":-3}',
	'{"column":14,"row":-12,"s":-2}',
	'{"column":12,"row":-12,"s":0}',
	'{"column":11,"row":-12,"s":1}',
	'{"column":13,"row":-12,"s":-1}',
	'{"column":10,"row":-12,"s":2}',
	'{"column":9,"row":-12,"s":3}',
	'{"column":8,"row":-12,"s":4}',
	'{"column":6,"row":-12,"s":6}',
	'{"column":4,"row":-12,"s":8}',
	'{"column":5,"row":-12,"s":7}',
	'{"column":-8,"row":-11,"s":19}',
	'{"column":-8,"row":-10,"s":18}',
	'{"column":-9,"row":-9,"s":18}',
	'{"column":-9,"row":-8,"s":17}',
	'{"column":-10,"row":-7,"s":17}',
	'{"column":-10,"row":-6,"s":16}',
	'{"column":-11,"row":-5,"s":16}',
	'{"column":-11,"row":-4,"s":15}',
	'{"column":-12,"row":-3,"s":15}',
	'{"column":-12,"row":-2,"s":14}',
	'{"column":-13,"row":-1,"s":14}',
	'{"column":-13,"row":0,"s":13}',
	'{"column":-14,"row":1,"s":13}',
	'{"column":-14,"row":2,"s":12}',
	'{"column":-15,"row":3,"s":12}',
	'{"column":-15,"row":4,"s":11}',
	'{"column":-16,"row":5,"s":11}',
	'{"column":-16,"row":6,"s":10}',
	'{"column":-17,"row":7,"s":10}',
	'{"column":-17,"row":8,"s":9}',
	'{"column":-18,"row":9,"s":9}',
	'{"column":-18,"row":10,"s":8}',
	'{"column":-19,"row":11,"s":8}',
	'{"column":-19,"row":12,"s":7}',
	'{"column":-18,"row":12,"s":6}',
	'{"column":-17,"row":12,"s":5}',
	'{"column":-16,"row":12,"s":4}',
	'{"column":-14,"row":12,"s":2}',
	'{"column":-13,"row":12,"s":1}',
	'{"column":-11,"row":12,"s":-1}',
	'{"column":-10,"row":12,"s":-2}',
	'{"column":-9,"row":12,"s":-3}',
	'{"column":-7,"row":12,"s":-5}',
	'{"column":-6,"row":12,"s":-6}',
	'{"column":-5,"row":12,"s":-7}',
	'{"column":-4,"row":12,"s":-8}',
	'{"column":-2,"row":12,"s":-10}',
	'{"column":-1,"row":12,"s":-11}',
	'{"column":1,"row":12,"s":-13}',
	'{"column":2,"row":12,"s":-14}',
	'{"column":3,"row":12,"s":-15}',
	'{"column":4,"row":12,"s":-16}',
	'{"column":5,"row":12,"s":-17}',
	'{"column":6,"row":12,"s":-18}',
	'{"column":7,"row":12,"s":-19}',
	'{"column":0,"row":12,"s":-12}',
	'{"column":-3,"row":12,"s":-9}',
	'{"column":-8,"row":12,"s":-4}',
	'{"column":-12,"row":12,"s":0}',
	'{"column":-15,"row":12,"s":3}',
	'{"column":18,"row":-10,"s":-8}',
	'{"column":18,"row":-9,"s":-9}',
	'{"column":17,"row":-8,"s":-9}',
	'{"column":17,"row":-7,"s":-10}',
	'{"column":16,"row":-6,"s":-10}',
	'{"column":16,"row":-5,"s":-11}',
	'{"column":15,"row":-4,"s":-11}',
	'{"column":15,"row":-3,"s":-12}',
	'{"column":14,"row":-2,"s":-12}',
	'{"column":14,"row":-1,"s":-13}',
	'{"column":13,"row":1,"s":-14}',
	'{"column":12,"row":2,"s":-14}',
	'{"column":12,"row":3,"s":-15}',
	'{"column":11,"row":4,"s":-15}',
	'{"column":11,"row":5,"s":-16}',
	'{"column":10,"row":6,"s":-16}',
	'{"column":9,"row":8,"s":-17}',
	'{"column":9,"row":9,"s":-18}',
	'{"column":8,"row":10,"s":-18}',
	'{"column":8,"row":11,"s":-19}',
	'{"column":10,"row":7,"s":-17}',
	'{"column":13,"row":0,"s":-13}',
	'{"column":-6,"row":-8,"s":14}',
	'{"column":-5,"row":-9,"s":14}',
	'{"column":-4,"row":-10,"s":14}',
	'{"column":-7,"row":-7,"s":14}',
	'{"column":-7,"row":-6,"s":13}',
	'{"column":-7,"row":-5,"s":12}',
	'{"column":-8,"row":-4,"s":12}',
	'{"column":-9,"row":-3,"s":12}',
	'{"column":-16,"row":11,"s":5}',
	'{"column":-16,"row":10,"s":6}',
	'{"column":-16,"row":9,"s":7}',
	'{"column":-15,"row":8,"s":7}',
	'{"column":-14,"row":7,"s":7}',
	'{"column":-13,"row":6,"s":7}',
	'{"column":-12,"row":5,"s":7}',
	'{"column":-12,"row":2,"s":10}',
	'{"column":-12,"row":3,"s":9}',
	'{"column":-11,"row":3,"s":8}',
	'{"column":-10,"row":3,"s":7}',
	'{"column":-10,"row":4,"s":6}',
	'{"column":-10,"row":5,"s":5}',
	'{"column":-10,"row":6,"s":4}',
	'{"column":-11,"row":7,"s":4}',
	'{"column":1,"row":9,"s":-10}',
	'{"column":2,"row":7,"s":-9}',
	'{"column":3,"row":6,"s":-9}',
	'{"column":3,"row":5,"s":-8}',
	'{"column":4,"row":4,"s":-8}',
	'{"column":1,"row":8,"s":-9}',
	'{"column":-2,"row":-7,"s":9}',
	'{"column":-1,"row":-6,"s":7}',
	'{"column":-2,"row":-6,"s":8}',
	'{"column":0,"row":-5,"s":5}',
	'{"column":-1,"row":-5,"s":6}',
	'{"column":1,"row":-6,"s":5}',
	'{"column":2,"row":-7,"s":5}',
	'{"column":3,"row":-8,"s":5}',
	'{"column":4,"row":-9,"s":5}',
	'{"column":12,"row":-9,"s":-3}',
	'{"column":12,"row":-8,"s":-4}',
	'{"column":12,"row":-7,"s":-5}',
	'{"column":12,"row":-6,"s":-6}',
	'{"column":12,"row":-5,"s":-7}',
	'{"column":12,"row":-4,"s":-8}',
	'{"column":11,"row":-3,"s":-8}',
	'{"column":9,"row":-2,"s":-7}',
	'{"column":8,"row":-2,"s":-6}',
	'{"column":7,"row":-3,"s":-4}',
	'{"column":7,"row":-4,"s":-3}',
	'{"column":7,"row":-5,"s":-2}',
	'{"column":7,"row":-6,"s":-1}',
	'{"column":7,"row":-7,"s":0}',
	'{"column":7,"row":-9,"s":2}',
	'{"column":7,"row":-8,"s":1}',
	'{"column":7,"row":-10,"s":3}',
	'{"column":8,"row":-11,"s":3}',
	'{"column":7,"row":-2,"s":-5}',
	'{"column":10,"row":-5,"s":-5}',
	'{"column":9,"row":-5,"s":-4}',
	'{"column":8,"row":-4,"s":-4}',
	'{"column":11,"row":-9,"s":-2}',
	'{"column":10,"row":-9,"s":-1}',
	'{"column":9,"row":-8,"s":-1}',
	'{"column":9,"row":-7,"s":-2}',
	'{"column":7,"row":5,"s":-12}',
	'{"column":6,"row":6,"s":-12}',
	'{"column":5,"row":7,"s":-12}',
	'{"column":8,"row":5,"s":-13}',
	'{"column":9,"row":5,"s":-14}',
	'{"column":10,"row":5,"s":-15}',
	'{"column":-5,"row":3,"s":2}',
	'{"column":-4,"row":4,"s":0}',
	'{"column":-3,"row":5,"s":-2}',
	'{"column":0,"row":4,"s":-4}',
	'{"column":1,"row":3,"s":-4}',
	'{"column":-2,"row":5,"s":-3}',
	'{"column":-4,"row":5,"s":-1}',
	'{"column":-5,"row":4,"s":1}',
	'{"column":-6,"row":3,"s":3}',
	'{"column":-7,"row":4,"s":3}',
	'{"column":2,"row":3,"s":-5}',
	'{"column":-4,"row":-3,"s":7}',
	'{"column":-4,"row":-2,"s":6}',
	'{"column":-5,"row":-1,"s":6}',
	'{"column":-5,"row":0,"s":5}',
	'{"column":-6,"row":1,"s":5}',
	'{"column":-7,"row":1,"s":6}',
	'{"column":-8,"row":1,"s":7}',
	'{"column":4,"row":-3,"s":-1}',
	'{"column":4,"row":-2,"s":-2}',
	'{"column":-7,"row":8,"s":-1}',
	'{"column":-8,"row":9,"s":-1}',
	'{"column":-9,"row":10,"s":-1}',
	'{"column":-9,"row":11,"s":-2}',
	'{"column":-1,"row":6,"s":-5}',
	'{"column":-1,"row":7,"s":-6}',
	'{"column":-3,"row":9,"s":-6}',
	'{"column":-1,"row":5,"s":-4}',
	'{"column":-2,"row":8,"s":-6}',
	'{"column":6,"row":1,"s":-7}',
	'{"column":5,"row":2,"s":-7}',
	'{"column":6,"row":0,"s":-6}',
	'{"column":4,"row":-4,"s":0}',
	'{"column":5,"row":-5,"s":0}',
	'{"column":5,"row":-2,"s":-3}',
	'{"column":-1,"row":-11,"s":12}',
	'{"column":-2,"row":-10,"s":12}',
	'{"column":-2,"row":-9,"s":11}',
	'{"column":-6,"row":-4,"s":10}',
	'{"column":-5,"row":-4,"s":9}',
	'{"column":-7,"row":-3,"s":10}',
	'{"column":-7,"row":-2,"s":9}',
	'{"column":-8,"row":-1,"s":9}',
	'{"column":-9,"row":0,"s":9}',
	'{"column":-10,"row":8,"s":2}',
	'{"column":-9,"row":8,"s":1}',
	'{"column":-1,"row":10,"s":-9}',
	'{"column":-2,"row":11,"s":-9}',
	'{"column":-2,"row":10,"s":-8}',
	'{"column":10,"row":0,"s":-10}',
	'{"column":10,"row":1,"s":-11}',
	'{"column":11,"row":1,"s":-12}',
	'{"column":12,"row":1,"s":-13}',
	'{"column":9,"row":2,"s":-11}',
	'{"column":8,"row":2,"s":-10}',
	'{"column":3,"row":10,"s":-13}',
	'{"column":2,"row":11,"s":-13}',
	'{"column":-12,"row":-1,"s":13}',
	'{"column":-11,"row":-1,"s":12}',
	'{"column":-11,"row":0,"s":11}',
	'{"column":-13,"row":10,"s":3}',
	'{"column":-13,"row":11,"s":2}',
	'{"column":-14,"row":9,"s":5}',
	'{"column":-13,"row":9,"s":4}',
	'{"column":15,"row":-9,"s":-6}',
	'{"column":15,"row":-8,"s":-7}',
	'{"column":16,"row":-8,"s":-8}',
];

export default DEFAULT_WALLS;
