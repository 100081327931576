import { useTranslation } from 'next-i18next';
import { Post } from '@/core/models';
import { Link } from '@/components';

export const TopItem = ({ post }: { post: Post }) => {
	const { t } = useTranslation('common');

	const {
		slug,
		publishedAt,
		title,
		shortBody,
		body,
		heroImage,
		readingTime,
		categories: keywords,
	} = post;

	const mainImage = getImage();

	function getImage() {
		let image;
		try {
			image = JSON.parse(body).blocks[0].data.file.url;
		} catch (error) {
			Error('Pleace, fix story and upload image');
		}
		return image;
	}

	return (
		<div className='px-0 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-0 lg:py-8'>
			<div className='flex flex-col max-w-screen-lg overflow-hidden border dark:border-gray-700 lg:flex-row'>
				<div className='relative lg:w-1/2'>
					<img
						src={mainImage}
						alt=''
						className='object-cover object-left w-full lg:absolute h-80 lg:h-full'
					/>
				</div>
				<div className='flex flex-col justify-center p-8 lg:p-16 lg:pl-10 lg:w-1/2'>
					<div>
						<p className='inline-block px-0 py-px mb-4 text-sm font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400'>
							<span className='inline-block py-1 px-2 rounded bg-gray-100 dark:bg-gray-600 text-green-500 dark:text-gray-100 text-sm font-medium tracking-widest'>
								{t('post-new')}
							</span>
						</p>
					</div>
					<Link
						href={`/post/${slug}`}
						className='mb-3 text-3xl font-extrabold leading-none sm:text-2xl hover:text-gray-600'
					>
						{title}
					</Link>
					<p className='mb-5 text-gray-800 dark:text-gray-100'>{shortBody}</p>
					<div className='flex items-center'>
						<Link
							href={`/post/${slug}`}
							className='inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide bg-green-500 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-green-600 focus:shadow-outline focus:outline-none'
						>
							{t('read-more')}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
