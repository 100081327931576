import { useTranslation } from 'next-i18next';
import { Post } from '@/core/models';
import { Link, Tag, PageSeo } from '@/components';
import dayjs from 'dayjs';

export const ListItem = ({ posts }: { posts: Post[] }) => {
	const { t } = useTranslation('common');

	return (
		<section className='text-gray-600 body-font overflow-hidden'>
			<div className='container px-5 py-10 mx-auto'>
				<div className='flex flex-wrap -m-12'>
					{posts.map((post) => {
						const {
							slug,
							publishedAt,
							title,
							shortBody,
							heroImage,
							readingTime,
							categories: keywords,
						} = post;
						return (
							<div key={slug} className='p-12 md:w-1/2 flex flex-col items-start'>
								<span className='inline-block py-1 px-2 rounded bg-gray-100 dark:bg-gray-600 text-green-500 dark:text-gray-100 text-xs font-medium tracking-widest'>
									{t('post-category')}
								</span>
								<h2 className='sm:text-3xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4'>
									<Link href={`/post/${slug}`} className='text-gray-900 dark:text-gray-100'>
										{title}
									</Link>
								</h2>
								<p className='leading-relaxed mb-8 dark:text-gray-400'>{shortBody}</p>
								<div className='flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto w-full'>
									<Link
										href={`/post/${slug}`}
										className='text-green-500 hover:text-blue-600 dark:hover:text-blue-400'
										aria-label={`Read "${title}"`}
									>
										{t('read-more')} ·
									</Link>
									<span className='sm:text-sm pl-3 text-muted text-grey-500 dark:text-gray-400'>
										{readingTime}
									</span>
									<span className='text-gray-400 mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200'>
										<svg
											className='w-4 h-4 mr-1'
											stroke='currentColor'
											strokeWidth={2}
											fill='none'
											strokeLinecap='round'
											strokeLinejoin='round'
											viewBox='0 0 24 24'
										>
											<path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />
											<circle cx={12} cy={12} r={3} />
										</svg>
										1.2K
									</span>
									<span className='text-gray-400 inline-flex items-center leading-none text-sm'>
										{dayjs.unix(+publishedAt / 1000).format('DD/MM/YYYY')}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};
