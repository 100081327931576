import Link from 'next/link';
import config from '@/config';
import { SocialIcon } from './social-icons';

export const Footer = () => (
	<footer className='text-gray-600 body-font'>
		<div className='container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col'>
			<a className='flex title-font font-medium items-center md:justify-start justify-center text-gray-500'>
				<span className='ml-3 text-sm'>{config.author}</span>
			</a>
			<p className='text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4'>
				{`© ${new Date().getFullYear()}`} {config.title} —
				<a
					href='https://twitter.com/knyttneve'
					className='text-gray-600 ml-1'
					rel='noopener noreferrer'
					target='_blank'
				>
					@kramarenko
				</a>
			</p>
			<span className='inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start'>
				<a className='text-gray-500' href={config.facebook} target='_blank'>
					<svg
						fill='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						className='w-5 h-5'
						viewBox='0 0 24 24'
					>
						<path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z' />
					</svg>
				</a>
				<a className='ml-3 text-gray-500' href={config.twitter} target='_blank'>
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z" />
                  </svg>
				</a>
				<a className='ml-3 text-gray-500' href={config.youtube} target='_blank'>
					<svg
						fill='none'
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						className='w-5 h-5'
						viewBox='0 0 24 24'
					>
						<rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
						<path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01' />
					</svg>
				</a>
				<a
					className='ml-3 text-gray-500'
					href={config.linkedin}
					target='_blank'
				>
					<svg
						fill='currentColor'
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={0}
						className='w-5 h-5'
						viewBox='0 0 24 24'
					>
						<path
							stroke='none'
							d='M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z'
						/>
						<circle cx={4} cy={4} r={2} stroke='none' />
					</svg>
				</a>
			</span>
		</div>
	</footer>
);

// <div className='flex flex-col items-center mt-16'>
// 	<div className='flex mb-3 space-x-4'>
// 		<SocialIcon kind='mail' href={`mailto:${config.email}`} size='6' />
// 		<SocialIcon kind='github' href={config.github} size='6' />
// 		<SocialIcon kind='facebook' href={config.facebook} size='6' />
// 		<SocialIcon kind='youtube' href={config.youtube} size='6' />
// 		<SocialIcon kind='linkedin' href={config.linkedin} size='6' />
// 		<SocialIcon kind='twitter' href={config.twitter} size='6' />
// 	</div>
// 	<div className='flex mb-2 space-x-2 text-sm text-gray-500 dark:text-gray-400'>
// 		<div>{config.author}</div>
// 		<div>{` • `}</div>
// 		<div>{`© ${new Date().getFullYear()}`}</div>
// 		<div>{` • `}</div>
// 		<Link href='/'>{config.title}</Link>
// 	</div>
// </div>;
