import Link from 'next/link';
import { kebabCase } from '@/libs/utils';

export const Tag = ({ text }) => {
	return (
		<Link href={`/tags/${kebabCase(text)}`}>
			<a className='mr-3 text-sm font-medium text-grey-500 uppercase hover:text-blue-600 dark:hover:text-blue-400'>
				#{text.split(' ').join('-')}
			</a>
		</Link>
	);
};
